import request from './http'

const global = {
  GOODS_LIST_IMG_TYPE: 1, //  管理端数据设置 SPU列表缩略图
  GOODS_SWIPER_IMG_TYPE: 2, //  商品详情轮播图片
  GOODS_DETAIL_IMG_TYPE: 3, //  商品详情内说明图片
  //  数字缩写
  numAbbr: function (num) {
    if (num >= 10000000) { //  大于等于一千万
      let temp = Math.floor(num / 1000000)
      return (temp / 10) + "千万"
    } else if (num >= 10000) {//  大于等于一万
      let temp = Math.floor(num / 1000)
      return (temp / 10) + "万"
    } else {
      return num;
    }
  },
  //  保留小数位
  decimalPlace: function (num, digit) {
    return Number(num).toFixed(digit);
  },
  //  数组去重
  arrNoRepeat(arr) {
    return [...new Set(arr)];
  },
  //  数组交集
  arrIntersection(arr1, arr2) {
    return arr1.filter(v => arr2.includes(v));
  },
  //  判断对象为空 或者对象值为空
  isObjValEmpty(obj) {
    let keys = Object.keys(obj);
    if (!keys.length) return true;
    return !keys.find(item => !!item); //  找到有值
  },
  //  判断两个对象是否相等（非嵌套情况）
  isObjEqual(obj1, obj2) {
    let props1 = Object.getOwnPropertyNames(obj1);
    let props2 = Object.getOwnPropertyNames(obj2);
    if (props1.length != props2.length) {
      return false;
    }
    for (let i = 0; i < props1.length; i++) {
      let propName = props1[i];
      if (obj1[propName] !== obj2[propName]) {
        return false;
      }
    }
    return true;
  },
  //  深拷贝
  deepClone(source, weakMap = new WeakMap()) {
    if (source == undefined || typeof source != 'object') {
      return source;
    }
    if (source instanceof Date) {
      return new Date(source);
    }
    if (source instanceof RegExp) {
      return new RegExp(source);
    }
    const val = weakMap.get(source);
    if (val) return val;
    const target = new source.constructor();
    weakMap.set(source, target);
    for (let k in source) {
      target[k] = global.deepClone(source[k], weakMap);
    }
    return target;
  },
  //  根据场景获取商品列表（场景值，目标页数，请求一页数量，回调函数）
  async getGoodsList(sceneNo, page, size, fun) {
    let res = await request.get("/api/scm/sale/scene/spu/list", {
      sceneNo,
      page,
      size
    })
    if (!!fun && res) fun(res);
  },
  //  生成订单Id(用户id 商品skuid)
  createOrderId(userId, prId) {
    //  生成规则 时间戳（毫秒）+ 用户id（后两位）+商品id（后两位）+ 随机数（三位） 共20位
    return new Date().getTime() + this.getNumLastDoubleDigit(userId) + this.getNumLastDoubleDigit(prId) + this.satisfyFull(Math.floor(Math.random() * 1000), 3);
  },
  //  获取数字最后两位数
  getNumLastDoubleDigit(num) {
    let tmp = String(num).substr(-2, 2);
    return this.satisfyFull(tmp, 2);
  },
  //  补足位数
  satisfyFull(num, length) {
    num = String(num);
    if (num.length >= length) return num;
    return (new Array(length - num.length).fill(0)).join("") + num;
  }
}

export default global