import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import global from './utils/global'
import request from './utils/http'
import clipboard from 'clipboard'
import Toast from "vant/lib/toast";
import List from "vant/lib/list";
import Dialog from "vant/lib/dialog";
import Loading from "vant/lib/loading";

import "vant/lib/toast/style";
import "vant/lib/list/style";
import "vant/lib/dialog/style";
import "vant/lib/loading/style";

import './utils/rem'
import "@/utils/swiper/swiper.min.css";
import Swiper from "@/utils/swiper/swiper.min.js";
import '@/assets/css/common.scss'
Vue.prototype.$Swiper = Swiper
Vue.config.productionTip = false
Vue.prototype.global = global
Vue.prototype.request = request
Vue.prototype.clipboard = clipboard

Vue.use(Toast);
Vue.use(List);
Vue.use(Dialog);
Vue.use(Loading);
// document.cookie='DSP_AC=E4eZDFl9feAyRQUtLsHmywNX6V9RB3FSufXH';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
