<template>
    <div class="tabItem">
        <!-- <div class="sel">首页</div>
        <div>我的</div> -->
        <div  v-for='(item,index) in tabList' :key="index" class="tabBox">
            <div class="images" @click="jump(item.jumpName,item.path)">
                <img :src="item.tabStatus?item.tabSelImages:item.tabunSelImages" alt="">
            </div>
            <p :class="item.tabStatus?'sel':''">{{item.tabName}}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: 'footTab',
    props: {
        
    },
    data(){
        return{
            tabList:[{
               tabName:'首页',
               tabSelImages:require('@/assets/userCenter/selHome.png'),
               tabunSelImages:require('@/assets/userCenter/unselHome.png'),
               tabStatus:true,
               jumpName:'hotSale',
               path:'/h/scm/mall/hotSale'
            },{
               tabName:'我的',
               tabSelImages:require('@/assets/userCenter/selMine.png'),
               tabunSelImages:require('@/assets/userCenter/unselMine.png'),
               tabStatus:false,
               jumpName:'userCenter',
                path: '/h/scm/userCenter'
            }]
        }
    },
    watch:{
        '$route':'getPath' 
    },
    methods:{
        getPath(){
            const {index} = this.$route.meta;
            if(index!=undefined){
              this.tabList.forEach((item,index)=>{
                  item.tabStatus = false
              }) 
              this.tabList[index].tabStatus = true
            }
        },
        jump(routerName,currentPath){
            if(currentPath==this.$route.path){
                return
            }
            this.$router.push({name:routerName,query:{
                 scence:this.$route.query.scence 
            }})
        }
    }
}
</script>
<style scoped lang='scss'>
    .tabItem{
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 999;
        width: 100%;
        height: 120px;
        background: #FFFFFF;
        display: flex;
        display: -webkit-flex;
        justify-content: center;
        border-top: 1px solid #CFCFCF;
        .tabBox{
            height: 120px;
            width: 50px;
            text-align: center;
            color: #fff;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .images{
                width: 44px;
                height: 44px;
                img{
                    display:block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .tabBox:nth-of-type(1){
            margin-right: 247px;
        }
        .sel{
            color: red;
        }
        p{
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
        }
    }
</style>