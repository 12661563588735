import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  //  默认路由
  // {
  //   path: '*',
  //   redirect: '/h/scm/mall/hotSale'
  // },
  {
    path: '/h/n/mobile/login',
    name: 'login',
    meta: {
      title: '多多省',
      nav: true
    },
    component: () => import('@/views/user/index')
  },
  {
    path: '/h/scm/home',
    name: 'vipMall',
    component: () => import('../views/mall/mall.vue'),
    meta: {
      title: '会员商场'
    },
  },
  {
    path: '/h/scm/mall/hotSale',
    name: 'hotSale',
    component: () => import('../views/newMall/index.vue'),
    meta: {
      title: '多多省优惠专区',
      hasTab:true,
      index:0
    },
  },
  // {
  //   path: '/h/scm/mall/hotSale',
  //   name: 'hotSale',
  //   component: () => import('../views/mall/hotSale.vue'),
  //   meta: {
  //     title: '热销商品专区'
  //   },
  // },
  {
    path: '/h/scm/mall/list',
    name: 'mallList',
    component: () => import('../views/mall/list.vue'),
    meta: {
      title: '会员商品列表',
      hasTab:false
    },
  },
  {
    path: '/h/scm/mall/goodsDetail',
    name: 'goodsDetail',
    component: () => import('../views/mall/goodsDetail.vue'),
    meta: {
      title: '商品详情',
      hasTab:false
    },
  },
  {
    path: '/h/scm/member/myCoupon',
    name: 'myCoupon',
    component: () => import('../views/member/myCoupon.vue'),
    meta: {
      title: '会员优惠券',
      hasTab:false
    },
  },
  {
    path: '/h/scm/member/myAddr',
    name: 'myAddr',
    component: () => import('../views/member/myAddr.vue'),
    meta: {
      title: '我的地址',
      hasTab:false
    },
  },
  {
    path: '/h/scm/member/editAddr',
    name: 'editAddr',
    component: () => import('../views/member/editAddr.vue'),
    meta: {
      title: '编辑地址',
      hasTab:false
    },
  },
  {
    path: '/h/scm/order/orderForm',
    name: 'orderForm',
    component: () => import('../views/order/orderForm.vue'),
    meta: {
      title: '确认订单',
      hasTab:false
    },
  },
  {
    path: '/h/scm/order/orderPay',
    name: 'orderPay',
    component: () => import('../views/order/orderPay.vue'),
    meta: {
      title: '确认支付',
      hasTab:false
    },
  },
  {
    path: '/h/scm/order/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/order/orderDetail.vue'),
    meta: {
      title: '订单详情',
      hasTab:false
    },
  },
  {
    path: '/h/scm/order/orderList',
    name: 'orderList',
    component: () => import('../views/order/allOrderList.vue'),
    meta: {
      title: '全部订单',
      hasTab:false
    },
  },
  {
    path: '/h/scm/order/payCheck',
    name: 'payCheck',
    component: () => import('../views/order/payCheck.vue'),
    meta: {
      title: '支付确认',
      hasTab:false
    },
  },
  //  江西移动 兑换页面活动
  {
    path: '/h/scm/activity/jxExchange',
    name: 'jxExchange',
    component: () => import('../views/activity/jxExchange.vue'),
    meta: {
      title: '好物零元享',
      hasTab:false
    },
  },
  {
    path: '/h/scm/virtual/goodsDetail',
    name: 'virtual',
    component: () => import('../views/virtual/index.vue'),
    meta: {
      title: '商品详情',
      hasTab:false
    },
  },
  {
    path: '/h/scm/virtual/virtualForm',
    name: 'virtualPay',
    component: () => import('../views/virtual/virtualForm.vue'),
    meta: {
      title: '订单中转页',
      hasTab:false
    },
  },
  {
    path: '/h/scm/virtual/virtualPay',
    name: 'virtualPay',
    component: () => import('../views/virtual/virtualPay.vue'),
    meta: {
      title: '订单支付',
      hasTab:false
    },
  },
  {
    path: '/h/scm/virtual/virtualCode',
    name: 'virtualCode',
    component: () => import('../views/virtual/virtualCode.vue'),
    meta: {
      title: '查看卡券',
      hasTab:false
    },
  },
  {
    path: '/h/scm/userCenter',
    name: 'userCenter',
    component: () => import('../views/userCenter/index.vue'),
    meta: {
      title: '用户中心',
      hasTab:true,
      index:1
    },
  },
  {
    path: '/h/scm/discount',
    name: 'discount',
    component: () => import('../views/discount/index.vue'),
    meta: {
      title: '优惠券',
      hasTab:false,
    },
  },
  {
    path: '/h/scm/wxVirtual',
    name: 'wxVirtual',
    component: () => import('../views/virtual/wxVirtual.vue'),
    meta: {
      title: '支付中',
      hasTab:false,
    },
  },
  {
    path: '/h/scm/wxOrder',
    name: 'discount',
    component: () => import('../views/order/orderIndex.vue'),
    meta: {
      title: '支付中',
      hasTab:false,
    }
  },
  {
    path: '/h/n/yntrip',
    name: '',
    component: () => import('../views/yntrip/index.vue'),
    meta: {
      title: '',
      hasTab:false,
    }
  },{
    path: '/h/n/yntripOne',
    name: '',
    component: () => import('../views/yntrip/indexOne.vue'),
    meta: {
      title: '',
      hasTab:false,
    }
  },{
    path: '/h/n/yntripTwo',
    name: '',
    component: () => import('../views/yntrip/indexTwo.vue'),
    meta: {
      title: '',
      hasTab:false,
    }
  },
  {
    path: '/h/n/orderSure',
    name: '',
    component: () => import('../views/ynTripOrder/index.vue'),
    meta: {
      title: '确认订单',
      hasTab:false,
    }
  },
  {
    path: '/h/n/ynTripPayCheck',
    name: '',
    component: () => import('../views/ynPayCheck/payCheck.vue'),
    meta: {
      title: '确认支付',
      hasTab:false,
    }
  },
  {
    path: '/h/n/upSuccess',
    name: 'disount',
    component: () => import('../views/ynTripOrder/upSuccess.vue'),
    meta: {
      title: '提交成功',
      hasTab:false,
    }
  },
  {
    path: '/h/n/upUserInfo',
    name: 'disount',
    component: () => import('../views/ynTripOrder/upUserInfo.vue'),
    meta: {
      title: '填写信息',
      hasTab:false,
    }
  },
  {
    path: '/h/n/ynTripFree',
    name: '',
    component: () => import('../views/yntrip/indexFree.vue'),
    meta: {
      title: '畅游云南',
      hasTab:false,
    }
  },
   {
    path: '/h/n/orderFreeSure',
    name: '',
    component: () => import('../views/ynTripOrder/indexFree.vue'),
    meta: {
      title: '确认订单',
      hasTab:false,
    }
  },
  {
    path: '/h/n/ynTripCollect',
    name: '',
    component: () => import('../views/ynTripCollect/index.vue'),
    meta: {
      title: '畅游云南',
      hasTab:false,
    }
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
