// 设置 rem 函数
function setRem() {

    var deviceWidth = 750;
    var root = document.documentElement;
    var w = root.clientWidth;
    root.style.fontSize = (w > 750 ? 750 : w < 320 ? 320 : w) / deviceWidth * 100 + "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem()
}
