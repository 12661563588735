import axios from "axios";
import Vue from 'vue'
// import store from '../store/store'
// import global from '../utils/global'
import Toast from "vant/lib/toast";
import "vant/lib/toast/style";
Vue.use(Toast);
axios.defaults.withCredentials=true;
console.log(process.env.NODE_ENV)
// var jumpUrl = process.env.NODE_ENV=='development'?'http://wap.js.10086.cn/dicp/test1/temp/fawnFinance/html/zzindex.html?id=7kkedffgcs9920o':'http://wap.js.10086.cn/dicp/temp/fawnFinance/html/zzindex.html?id=7kkedffgcs9920o'
var jumpUrl = 'http://wap.js.10086.cn/dicp/temp/fawnFinance/html/zzindex.html?id=7kkedffgcs9920o';
const service = axios.create({
  timeout: 60000,
  withCredentials: true
});
console.log(document.cookie)
// 请求拦截器
service.interceptors.request.use(
  config => {
    // config.headers["Content-Type"] = "application/json";
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
  }
);
// 响应拦截器
service.interceptors.response.use(
  response => {
    // Do something with response data
    return checkStatus(response);
  },
  error => {
    // Do something with request error
    console.dir(error); // for debug
    Toast({
      message: error.toString(),
      position: "bottom"
    })
    return Promise.reject(error);
  }
);

/* 检查响应状态 */
function checkStatus(response) {
  console.log(response)
  if (response.data.code == 3000) {
    // window.location.href = jumpUrl;
    return
  }

  if (!!response.data.error) {
    Toast({
      message: response.data.error,
      position: "bottom"
    })
    return;
  } else {
    return response.data;
  }
}

export default {
  post(url, data) {
    return service({
      headers: {
        'Content-Type': 'application/json'
      },
      method: "post",
      url,
      data
    })
      .then(response => response && response.data);
  },
  postFrom(url, data) {
    return service({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: "post",
      url,
      data
    })
      .then(response => response && response.data);
  },
  put(url, data) {
    return service({
      headers: {
        'Content-Type': 'application/json'
      },
      method: "put",
      url,
      data
    })
      .then(response => response && response.data);
  },
  get(url, params = {}) {
    return service({
      headers: {
        'Content-Type': 'application/json'
      },
      method: "get",
      url,
      params,
    })
      // 不需要除了data之外的数据，直接 return response.data
      .then(response => response && response.data);
  }
};