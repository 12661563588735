import Vue from 'vue'
import Vuex from 'vuex'
import request from '../utils/http'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userAddr: [], //  用户地址列表 index首位 默认为默认地址
    defaultAddr: null,  //  默认地址
    userAddrUpdateTime: 0,  //  上次获取地址接口时间戳，防止
    sceneNo: "" //  记录场景编号
  },
  mutations: {
    //  更新用户默认地址
    UPDATEDEFAULTADDR(state, data) {
      state.defaultAddr = data || null;
    },
    //  更新用户地址列表
    UPDATEUSERADDR(state, data) {
      //  todo 将默认地址放在首位
      state.userAddr = data || [];
    },
    //  更新接口获取时间戳
    UPDATEUSERADDRTIME(state, time) {
      state.userAddrUpdateTime = time || 0;
    },
    UPDATESCENCENO(state, no) {
      state.sceneNo = no || "";
      if (!!no) sessionStorage.setItem("sceneNo", no);
    }
  },
  actions: {
    //  获取用户地址 time 默认 300000 为5分钟内不再调用接口 
    getUserAddr(context, fn, time = 300000) {
      console.log(context)
      let now = new Date().getTime();
      if (now - context.state.userAddrUpdateTime > time) {
        context.commit("UPDATEUSERADDRTIME", now);
        request.get("/api/scm/user/address/list", {
          page: 1,
          size: 100
        }).then((res) => {
          if (res) {
            let list = [];
            res.forEach(item => {
              if (item.isDefault == 1) {
                //  默认地址
                list.unshift(item);
              } else {
                list.push(item);
              }
            });
            context.commit("UPDATEUSERADDR", list);
            if (!!fn) fn();
          }
        });
      } else {
        if (!!fn) fn();
      }

    }
  },
  modules: {
  }
})
