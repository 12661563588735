<template>
  <div id="app">
    <div class="main">
      <router-view />
      <footTab v-show="this.$route.meta.hasTab" />
    </div>
  </div>
</template>
<script>
import footTab from '@/components/footTab/index.vue'
export default {
  data(){
    return{

    }
  },
  watch:{

  },
  components: {
   footTab
  },
  methods:{

  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
body,
html {
  width: 100%;
  height: 100%;
  background: #fff;
  color: #000;
  -webkit-overflow-scrolling: touch;
}
a {
  outline: 0;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
a:visited {
  color: inherit;
}
img {
  border: 0;
}
em {
  font-style: normal;
}
button {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}
textarea:focus,
input:focus {
  outline: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
}
.main {
  max-width: 750px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  .fixedMode {
    height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .mainScroll {
    flex: 1;
    overflow-y: auto;
  }
}
</style>
